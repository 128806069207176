<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item active">
          <a class="nav-link text-muted" href="javascript:void(0)"><i class="fa fa-flask"></i> Laboratorios</a>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" href="#">Link</a>
        </li> -->
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" data-toggle="dropdown">
            Laboratorios
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <router-link class="dropdown-item" to="/dental-clinic/laboratories">Habilitados</router-link>
            <router-link class="dropdown-item" to="/dental-clinic/laboratories/disables">Deshabilitados</router-link>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" data-toggle="dropdown">
            <i class="fa fa-usd"></i> Prestaciones de laboratorio
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" href="#">Habilitadas</a>
            <a class="dropdown-item" href="#">Deshabilitadas</a>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" data-toggle="dropdown">
            <i class="fa fa-file-o"></i> Solicitudes
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <router-link class="dropdown-item" to="/dental-clinic/laboratories/jobs?status=2">Solicitado</router-link>
            <router-link class="dropdown-item" to="/dental-clinic/laboratories/jobs?status=3">En proceso</router-link>
            <router-link class="dropdown-item" to="/dental-clinic/laboratories/jobs?status=4">En revision</router-link>
            <router-link class="dropdown-item" to="/dental-clinic/laboratories/jobs?status=6">Finalizada</router-link>
          </div>
        </li>
      </ul>
      <form class="form-inline my-2 my-lg-0">
        <button class="btn btn-success my-2 my-sm-0">
          <i class="fa fa-plus"></i> Nuevo Laboratorio
        </button>
      </form>
    </div>
  </nav>
</template>
<script>
  export default {
    components: {
      //
    },

    // directives
    // filters

    props: {
      //
    },

    data: () => ({
      //
    }),

    computed: {
      //
    },

    watch: {
      //
    },

    mounted() {
      //
    },

    methods: {
      //
    }
  };
</script>

<style></style>